import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Главная | Добро пожаловать в Eternal Vows
			</title>
			<meta name={"description"} content={"Crafting Timeless Celebrations - Eternal Vows, где каждая свадьба превращается в шедевр"} />
			<meta property={"og:title"} content={"Главная | Добро пожаловать в Eternal Vows"} />
			<meta property={"og:description"} content={"Crafting Timeless Celebrations - Eternal Vows, где каждая свадьба превращается в шедевр"} />
			<link rel={"shortcut icon"} href={"https://ploofix.com/img/434634622.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ploofix.com/img/434634622.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ploofix.com/img/434634622.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ploofix.com/img/434634622.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ploofix.com/img/434634622.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ploofix.com/img/434634622.png"} />
			<meta name={"msapplication-TileColor"} content={"https://ploofix.com/img/434634622.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://ploofix.com/img/1.jpg) center center/cover no-repeat"
			min-height="60vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Добро пожаловать в Eternal Vows
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
					width="80%"
				>
					Откройте для себя мир свадеб, созданных по индивидуальному заказу, вместе с Eternal Vows, где свадьба вашей мечты воплотится в жизнь с элегантностью, отражающейся во времени. Мы специализируемся на создании торжеств, которые не только ослепляют, но и вызывают глубокий резонанс с личным путешествием каждой пары. Позвольте нам стать архитекторами вашего дня, где каждая деталь будет наполнена значимостью и красотой.
				</Text>
				<Link
					href="/contact-us"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-primary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Контакты
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" color="--dark">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				width="100%"
			>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					sm-width="100%"
					grid-column="1 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="flex-start"
				>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
					>
						<Image
							src="https://ploofix.com/img/2.jpg"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="cover"
							lg-max-height="392px"
							object-position="50% 50%"
							sm-max-height="213px"
							height="522px"
						/>
					</Box>
					<Box
						width="100%"
						padding="16px 12px 16px 12px"
						display="flex"
						align-items="flex-start"
						justify-content="flex-start"
						md-width="100%"
						md-border-width="0px"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Индивидуальный подход
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--dark">
						Каждая свадьба, которую мы создаем, так же уникальна, как и пара, которая ее празднует. Наш индивидуальный процесс планирования гарантирует, что ваша свадьба отразит ваш индивидуальный стиль и историю.
						</Text>
					</Box>
				</LinkBox>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					sm-width="100%"
					grid-column="2 / span 2"
					lg-grid-column="2 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="flex-start"
				>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
					>
						<Image
							src="https://ploofix.com/img/3.jpg"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="cover"
							lg-max-height="392px"
							object-position="50% 50%"
							sm-max-height="213px"
							height="522px"
						/>
					</Box>
					<Box
						width="100%"
						padding="16px 12px 16px 12px"
						display="flex"
						align-items="flex-start"
						justify-content="flex-start"
						md-width="100%"
						md-border-width="0px"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Незабываемые места проведения
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--dark">
						От очаровательных садов до величественных залов - наши эксклюзивные площадки создадут атмосферу для вашего незабываемого дня.
Изысканная кухня: Наши кулинарные эксперты создают индивидуальное меню, восхищающее чувства, с учетом ваших вкусов и диетических предпочтений.
						</Text>
					</Box>
				</LinkBox>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					lg-align-items="flex-start"
					sm-width="100%"
					grid-column="1 / span 2"
					lg-grid-column="1 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="flex-start"
				>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
					>
						<Image
							src="https://ploofix.com/img/4.jpg"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="cover"
							lg-max-height="392px"
							object-position="50% 50%"
							sm-max-height="213px"
							height="522px"
						/>
					</Box>
					<Box
						width="100%"
						padding="16px 12px 16px 12px"
						display="flex"
						align-items="flex-start"
						justify-content="flex-start"
						md-width="100%"
						md-border-width="0px"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Моменты, которые остаются на всю жизнь
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--dark">
						В Eternal Vows мы верим, что день вашей свадьбы должен быть таким же уникальным, как и ваша история любви. Сочетая нестареющие традиции и современные тенденции, мы подстраиваем каждый аспект вашего дня так, чтобы он отражал ваш личный стиль и желания. От интимных встреч до грандиозных торжеств - наша команда позаботится о том, чтобы каждый момент был незабываемым, безупречным и именно вашим.
 
						</Text>
					</Box>
				</LinkBox>
				<LinkBox
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
					flex-wrap="wrap"
					sm-width="100%"
					grid-column="3 / span 1"
					lg-grid-column="2 / span 1"
					sm-align-self="auto"
					sm-grid-column="auto"
					justify-content="flex-start"
				>
					<Box
						width="100%"
						align-items="flex-start"
						display="flex"
						justify-content="flex-start"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						lg-width="100%"
					>
						<Image
							src="https://ploofix.com/img/5.jpg"
							border-radius="24px"
							max-width="100%"
							width="100%"
							object-fit="cover"
							lg-max-height="392px"
							object-position="50% 50%"
							sm-max-height="213px"
							height="522px"
						/>
					</Box>
					<Box
						width="100%"
						padding="16px 12px 16px 12px"
						display="flex"
						align-items="flex-start"
						justify-content="flex-start"
						md-width="100%"
						md-border-width="0px"
						flex-direction="column"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Опыт без стресса
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--dark">
						Благодаря нашему тщательному вниманию к деталям и стремлению к совершенству, мы обеспечиваем беспроблемное и не вызывающее стресса путешествие к вашему знаменательному дню.
						</Text>
					</Box>
				</LinkBox>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Присоединяйтесь к нашему сообществу любви
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				Погрузитесь в мир "Вечных обетов" в нашем выставочном зале
				</Text>
				<Link
					href="/contact-us"
					padding="12px 44px 12px 44px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Посетите нас
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://ploofix.com/img/6.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652e9dee47aa25001fb0714b"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});